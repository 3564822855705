import { gql, NetworkStatus, useLazyQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../providers/CartContext'
import Loader from '../Loader'

const CartTotal = () => {
  const { cart } = useContext(CartContext)

  const [loadTotal, { loading, error, data, refetch, networkStatus }] =
    useLazyQuery(
      gql`
        query cartTotal($cartId: ID!) {
          cart(id: $cartId) {
            id
            cost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
        }
      `,
      { variables: { cartId: cart.id }, notifyOnNetworkStatusChange: true }
    )

  useEffect(() => {
    if (!cart.id) return
    refetch({ cartId: cart.id })
  }, [refetch, cart.id, cart.lastUpdate])

  if (!cart.id || !data || !data.cart) return null

  return (
    <div className="bg-background rounded px-4 py-2 mb-2 grid grid-cols-2 justify-between">
      <div className="font-semibold">Totale</div>
      {!loading && networkStatus !== NetworkStatus.refetch && data && !error ? (
        <div className={`font-semibold text-right`}>
          {Intl.NumberFormat('it-IT', {
            currency: data.cart?.cost?.totalAmount.currencyCode,
            currencyDisplay: 'symbol',
            unitDisplay: 'long',
            minimumFractionDigits: 2,
            style: 'currency',
          }).format(data.cart?.cost?.totalAmount.amount)}
        </div>
      ) : (
        <div className="text-right">
          <Loader />
        </div>
      )}
    </div>
  )
}

export default CartTotal
