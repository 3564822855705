import { gql, useLazyQuery, useMutation } from '@apollo/client'
import Image from 'next/image'
import { useCallback, useContext, useEffect, useState } from 'react'
import { FiMinus, FiPlus, FiTrash } from 'react-icons/fi'
import { useDebouncedCallback } from 'use-debounce'
import { CartContext } from '../../providers/CartContext'

const LineItem = ({ id }: { id: string }) => {
  const { cart, dispatch } = useContext(CartContext)

  const [_, { loading, data, refetch }] = useLazyQuery(
    gql`
      query cartLines($cartId: ID!) {
        cart(id: $cartId) {
          id
          lines(first: 250) {
            edges {
              node {
                id
                quantity
                cost {
                  subtotalAmount {
                    amount
                    currencyCode
                  }
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    sku
                    image {
                      id
                      url
                      altText
                      width
                      height
                    }
                    title
                    quantityAvailable
                    product {
                      id
                      title
                      requiresSellingPlan
                    }
                    customizableLabelPlaceholder: metafield(
                      namespace: "teatips"
                      key: "customizable_label_placeholder"
                    ) {
                      value
                    }
                  }
                }
                attributes {
                  key
                  value
                }
              }
            }
          }
        }
      }
    `,
    { variables: { cartId: cart.id }, ssr: false, fetchPolicy: 'network-only' }
  )

  // update lines when cart changes
  useEffect(() => {
    if (!cart.id) return
    refetch({ cartId: cart.id })
  }, [id, cart.id, cart.lastUpdate, refetch])

  const [thisLine, setThisLine] = useState<any | undefined>(undefined)

  // update line when lines change
  useEffect(() => {
    if (!data) return
    if (data.cart.lines.edges.length === 0) return
    const line = data.cart.lines.edges
      .map((edge: any) => edge.node)
      .find((line: any) => line.id === id)
    setThisLine(line)
  }, [data, id])

  const [localQuantity, setLocalQuantity] = useState(
    thisLine ? thisLine.quantity : 0
  )

  // update the local quantity when the current line's quantity changes
  useEffect(() => {
    setLocalQuantity(thisLine ? thisLine.quantity : 0)
  }, [thisLine])

  const [updateLineQuantityMutation] = useMutation(
    gql`
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
          }
        }
      }
    `
  )

  const [removeLineMutation] = useMutation(
    gql`
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
          }
        }
      }
    `
  )

  const updateLineQuantity = useCallback(() => {
    return updateLineQuantityMutation({
      variables: {
        cartId: cart.id,
        lines: {
          id,
          quantity: localQuantity,
        },
      },
    })
  }, [cart.id, id, localQuantity, updateLineQuantityMutation])

  const removeLine = useCallback(() => {
    return removeLineMutation({ variables: { cartId: cart.id, lineIds: [id] } })
  }, [cart.id, id, removeLineMutation])

  const updateRemoteCart = useDebouncedCallback(async () => {
    try {
      if (localQuantity === 0) {
        await removeLine()
      }

      if (localQuantity > 0) {
        await updateLineQuantity()
      }
    } catch (e) {
    } finally {
      dispatch({ type: 'UPDATE' })
    }
  }, 500)

  const add = () => {
    if (localQuantity >= thisLine.merchandise.quantityAvailable) return
    if (thisLine.merchandise.product.requiresSellingPlan) return
    setLocalQuantity(localQuantity + 1)
    updateRemoteCart()
  }

  const reduce = () => {
    if (localQuantity === 0) return
    setLocalQuantity(localQuantity - 1)
    updateRemoteCart()
  }

  const remove = () => {
    setLocalQuantity(0)
    updateRemoteCart()
  }

  if (loading || !thisLine) return null

  if (localQuantity === 0) return null

  return (
    <div
      className={`grid grid-cols-[auto_auto_auto_auto_1fr] auto-rows-min gap-y-2 bg-background p-4 mb-2 rounded`}
    >
      <div className="rounded col-span-4 row-span-2">
        <Image
          src={thisLine.merchandise.image.url}
          alt={thisLine.merchandise.image.altText}
          width={thisLine.merchandise.image.width}
          height={thisLine.merchandise.image.height}
          className="rounded"
          layout="responsive"
          sizes="150px"
        />
      </div>

      <div className="row-span-2 px-4">
        <h3 className="leading-none mb-1">
          {thisLine.merchandise.product.title}
        </h3>

        <div className="text-xs md:text-sm">{thisLine.merchandise.title}</div>

        {thisLine.attributes?.find(({ key }) => key === 'custom_label') ? (
          <div className="border-b-2 border-teatips-light my-2"></div>
        ) : null}

        {thisLine.attributes?.find(({ key }) => key === 'custom_label') ? (
          <div className="text-xs md:text-sm">
            {thisLine.merchandise.customizableLabelPlaceholder?.value ||
              'Personalizzazione'}
            :{' '}
            {
              thisLine.attributes.find(({ key }) => key === 'custom_label')
                .value
            }
          </div>
        ) : null}
      </div>

      <button
        className="border-x border-y border-teatips px-1 flex justify-center items-center rounded-l w-7"
        onClick={reduce}
      >
        <FiMinus className={`${localQuantity !== 0 ? '' : 'opacity-20'}`} />
      </button>

      <div className="border-r border-y border-teatips text-center px-1 w-7">
        {localQuantity}
      </div>

      <button
        className="border-r border-y border-teatips px-1 flex justify-center items-center w-7"
        onClick={add}
      >
        <FiPlus
          className={`${
            localQuantity < thisLine.merchandise.quantityAvailable &&
            !thisLine.merchandise.product.requiresSellingPlan
              ? ''
              : 'opacity-20'
          }`}
        />
      </button>

      <button
        className="border-r border-y border-teatips px-1 flex justify-center items-center w-7"
        onClick={remove}
      >
        <FiTrash />
      </button>

      <div className="text-center border-y border-r border-teatips rounded-r">
        {thisLine.cost.subtotalAmount &&
          thisLine.cost.subtotalAmount.amount !==
            thisLine.cost.totalAmount.amount && (
            <span className="line-through mr-2">
              {Intl.NumberFormat('it-IT', {
                currency: thisLine.cost.subtotalAmount.currencyCode,
                currencyDisplay: 'symbol',
                unitDisplay: 'long',
                minimumFractionDigits: 2,
                style: 'currency',
              }).format(thisLine.cost.subtotalAmount.amount)}
            </span>
          )}

        <span className="font-semibold">
          {thisLine.cost.totalAmount.amount === '0.0'
            ? 'Gratis'
            : Intl.NumberFormat('it-IT', {
                currency: thisLine.cost.totalAmount.currencyCode,
                currencyDisplay: 'symbol',
                unitDisplay: 'long',
                minimumFractionDigits: 2,
                style: 'currency',
              }).format(thisLine.cost.totalAmount.amount)}
        </span>
      </div>
    </div>
  )
}

export default LineItem
