import { useContext } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { CartContext } from '../../providers/CartContext'

const CartHeader = () => {
  const { dispatch } = useContext(CartContext)

  return (
    <div className="grid grid-cols-[1fr] align-center items-center mb-2 relative">
      <div
        className="cursor-pointer absolute left-0 top-0 bottom-0 w-[32px] flex items-center"
        onClick={() => dispatch({ type: 'CLOSE' })}
      >
        <FiArrowLeft className="text-2xl" />
      </div>

      <div className="font-serif text-2xl text-center">Il tuo carrello</div>
    </div>
  )
}

export default CartHeader
