import { useContext } from 'react'
import tw from 'tailwind-styled-components'
import { CartContext } from '../../providers/CartContext'
import CartConfirm from './CartConfirm'
import CartHeader from './CartHeader'
import CartLines from './CartLines'
import CartNotes from './CartNotes'
import CartTotal from './CartTotal'
import ExtraItemLine from './ExtraItemLine'
import FreeShippingLine from './FreeShippingLine'

interface WrapperProps {
  $hasExtraItemLine: boolean,
  $open: boolean,
}

const Wrapper = tw.div<WrapperProps>`
  fixed
  w-full
  md:w-1/2
  lg:w-[36vw]
  h-full
  grid
  ${({ $hasExtraItemLine }) => $hasExtraItemLine ? 'grid-rows-[auto_auto_auto_1fr_auto_auto_auto]' : 'grid-rows-[auto_auto_1fr_auto_auto_auto]'}
  bg-teatips-light
  px-4
  py-4
  top-0
  right-0
  z-30
  transition-transform
  duration-500
  ease-in-out
  md:shadow-xl
  max-w-prose
  ${({ $open }) => $open ? 'translate-x-0' : 'translate-x-full'}
`

const Cart = () => {
  const { cart } = useContext(CartContext)
  const hasExtraItemLine = true

  return (
    <Wrapper $open={cart.open} $hasExtraItemLine={hasExtraItemLine}>
      <CartHeader />

      <FreeShippingLine />

      {hasExtraItemLine && <ExtraItemLine />}

      <CartLines />

      <CartNotes />

      <CartTotal />

      <CartConfirm />
    </Wrapper>
  )
}

export default Cart
