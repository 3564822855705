import Link from "next/link"
import { useContext } from "react"
import { FiArrowRight } from "react-icons/fi"
import { CartContext } from "../../providers/CartContext"

const ExtraItemLine = () => {
  const { dispatch } = useContext(CartContext)
  return (
    <Link href="/products/confezione-regalo" passHref>
    <a onClick={() => dispatch({ type: 'CLOSE' })} className="block bg-background rounded px-4 py-2 mb-2 text-xs md:text-sm no-underline">
        <div className="text-center flex items-center justify-center gap-1">
        <span>🎁 Aggiungi una confezione regalo</span> <FiArrowRight className="text-base" />
        </div>
    </a>
    </Link>
  )
}

export default ExtraItemLine
