import { gql, NetworkStatus, useLazyQuery } from '@apollo/client'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { CartContext } from '../../providers/CartContext'
import { PixelContext } from '../../providers/PixelContext'
import { initiateCheckout as facebookInitiateCheckout } from '../../lib/facebookTracking'
import { GTagContext } from '../../providers/GTagContext'
import { beginCheckout as googleBeginCheckout } from '../../lib/googleTracking'

const CartConfirm = () => {
  const { cart, dispatch } = useContext(CartContext)

  const [
    _,
    { loading: checkoutUrlLoading, error, data, refetch, networkStatus },
  ] = useLazyQuery(
    gql`
      query cartConfirm($cartId: ID!) {
        cart(id: $cartId) {
          id
          checkoutUrl
          cost {
            totalAmount {
              amount
              currencyCode
            }
          }
          lines(first: 250) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    sku
                    priceV2 {
                      amount
                      currencyCode
                    }
                    compareAtPriceV2 {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { variables: { cartId: cart.id }, notifyOnNetworkStatusChange: true }
  )

  useEffect(() => {
    if (!cart.id) return
    refetch({ cartId: cart.id })
  }, [refetch, cart.id, cart.lastUpdate])

  const analyticsContext = useContext(GTagContext)
  const pixelContext = useContext(PixelContext)
  const initiateCheckout = useCallback(async () => {
    if (!data?.cart?.checkoutUrl) return
    return Promise.all([
      facebookInitiateCheckout(pixelContext),
      googleBeginCheckout(analyticsContext)(
        data?.cart?.cost?.totalAmount?.currencyCode,
        data?.cart?.cost?.totalAmount?.amount,
        data?.cart?.lines.edges
          .map((edge: any) => edge.node)
          .map((node: any) => ({
            item_id: node.merchandise.sku,
            price: Math.max(
              node.merchandise.compareAtPriceV2?.amount || 0,
              node.merchandise.priceV2.amount
            ),
            quantity: node.quantity,
            discount: Math.abs(
              (node.merchandise.compareAtPriceV2?.amount ||
                node.merchandise.priceV2.amount) -
              node.merchandise.priceV2.amount
            ),
          }))
      ),
    ])
  }, [
    data?.cart?.checkoutUrl,
    data?.cart?.cost?.totalAmount?.amount,
    data?.cart?.cost?.totalAmount?.currencyCode,
    data?.cart?.lines.edges,
    pixelContext,
    analyticsContext,
  ])

  const empty = useMemo(() => {
    return data?.cart?.lines?.edges?.length <= 0
  }, [data?.cart?.lines?.edges])

  if (empty) {
    return (
      <div
        className="flex justify-center items-center rounded bg-teatips font-semibold text-text px-4 py-2 cursor-pointer"
        onClick={() => dispatch({ type: 'CLOSE' })}
      >
        <FiArrowLeft className="text-2xl mr-2" /> Torna al Negozio
      </div>
    )
  }

  return (
    <div
      className={`${checkoutUrlLoading ||
          networkStatus === NetworkStatus.refetch ||
          error ||
          !data ||
          !data.cart
          ? 'grayscale opacity-50 pointer-events-none'
          : ''
        }`}
    >
      <a
        href={data?.cart?.checkoutUrl}
        onClick={() => {
          initiateCheckout()
        }}
        className="rounded bg-teatips-orange text-text no-underline w-full px-4 py-2 flex items-center justify-center shadow-xl"
      >
        <span className="mr-2 font-semibold">Prosegui</span>{' '}
        <FiArrowRight className="text-xl" />
      </a>
    </div>
  )
}

export default CartConfirm
