import { useLazyQuery, gql } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import tw from 'tailwind-styled-components'
import { CartContext } from '../../providers/CartContext'

const Wrapper = tw.div`
  bg-background
  rounded
  px-4
  py-2
  mb-2
  text-xs
  md:text-sm
`

const Inner = tw.div`
  text-center
`

const FreeShippingLine = () => {
  const { cart } = useContext(CartContext)

  const [_, { data, refetch }] = useLazyQuery(
    gql`
      query cartTotal($cartId: ID!) {
        cart(id: $cartId) {
          id
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          lines(first: 250) {
            edges {
              node {
                id
                merchandise {
                  ... on ProductVariant {
                    id
                    requiresShipping
                  }
                }
              }
            }
          }
        }
      }
    `,
    { variables: { cartId: cart.id }, notifyOnNetworkStatusChange: true }
  )

  const requiresShipping = useMemo(() => {
    if (!data) return true
    if (!data.cart) return true
    if (!data.cart.lines) return true
    if (!data.cart.lines.edges) return true
    if (data.cart.lines.edges.length <= 0) return true

    return data.cart.lines.edges.some((edge: any) => {
      return edge.node.merchandise.requiresShipping
    })
  }, [data])

  useEffect(() => {
    if (!cart.id) return
    refetch({ cartId: cart.id })
  }, [refetch, cart.id, cart.lastUpdate])

  const toStandardFreeShipping = useMemo(() => {
    if (!data) return
    if (!data.cart) return
    if (!data.cart.cost) return
    return (
      parseFloat(process.env.NEXT_PUBLIC_STANDARD_FREE_SHIPPING) -
      data.cart.cost.totalAmount.amount
    )
  }, [data])

  const toExpressFreeShipping = useMemo(() => {
    if (!data) return
    if (!data.cart) return
    if (!data.cart.cost) return
    return (
      parseFloat(process.env.NEXT_PUBLIC_EXPRESS_FREE_SHIPPING) -
      data.cart.cost.totalAmount.amount
    )
  }, [data])

  const nextFreeShippingType = useMemo(() => {
    if (toStandardFreeShipping > 0) return 'STANDARD'
    if (toExpressFreeShipping > 0) return 'EXPRESS'
  }, [toExpressFreeShipping, toStandardFreeShipping])

  if (!cart.id || !data || !data.cart) return null

  if (!requiresShipping) {
    return (
      <>
        <Wrapper>
          <Inner>
              <>
                💌 Riceverai i biglietti via email
              </>
          </Inner>
        </Wrapper>
      </>
    )
  }

  return (
    <>
      <Wrapper>
        <Inner>
          {nextFreeShippingType === 'STANDARD' ? (
            <>
              🚚 Aggiungi{' '}
              {Intl.NumberFormat('it-IT', {
                currency: data.cart.cost.totalAmount.currencyCode,
                currencyDisplay: 'symbol',
                unitDisplay: 'long',
                minimumFractionDigits: 2,
                style: 'currency',
              }).format(toStandardFreeShipping)}{' '}
              per la spedizione <strong>standard</strong> gratuita
            </>
          ) : (
            <>
              ✅ Hai raggiunto la spedizione <strong>standard</strong> gratuita
            </>
          )}
        </Inner>
      </Wrapper>
      {/* <Wrapper>
        <Inner>
          {['STANDARD', 'EXPRESS'].includes(nextFreeShippingType) ? (
            <>
              🚚 Aggiungi{' '}
              {Intl.NumberFormat('it-IT', {
                currency: data.cart.cost.totalAmount.currencyCode,
                currencyDisplay: 'symbol',
                unitDisplay: 'long',
                minimumFractionDigits: 2,
                style: 'currency',
              }).format(toExpressFreeShipping)}{' '}
              per la spedizione{' '}
              <strong>express</strong>{' '}
              gratuita
            </>
          ) : (
            <>
              ✅ Hai raggiunto la spedizione <strong>express</strong> gratuita
            </>
          )}
        </Inner>
      </Wrapper> */}
    </>
  )
}

export default FreeShippingLine
