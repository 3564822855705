import { gql, useMutation } from '@apollo/client'
import { useContext, useState } from 'react'
import { FiCheck, FiList } from 'react-icons/fi'
import { CartContext } from '../../providers/CartContext'

const CartNotes = () => {
  const { cart } = useContext(CartContext)

  const [open, setOpen] = useState(false)
  const [notes, setNotes] = useState('')

  const [cartNoteUpdate] = useMutation(gql`
    mutation cartNoteUpdate($cartId: ID!, $note: String!) {
      cartNoteUpdate(cartId: $cartId, note: $note) {
        cart {
          id
        }
      }
    }
  `)

  const updateNotes = async () => {
    setOpen(false)

    return cartNoteUpdate({
      variables: {
        cartId: cart.id,
        note: notes,
      },
    })
  }

  return (
    <div
      className={`bg-background px-4 py-2 mb-2 flex items-center text-xs md:text-sm relative ${
        open ? 'rounded-b' : 'rounded'
      }`}
    >
      <div
        className={`absolute top-0 left-0 w-full bg-background z-50 px-4 pt-4 pb-2 grid grid-cols-[1fr_auto] ${
          open ? '-translate-y-full rounded-t' : 'hidden'
        }`}
      >
        <input
          type="text"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Nome sul citofono e altre informazioni"
          className="w-full p-2 border-2 border-teatips rounded-l"
        />
        <div
          className="px-4 border-r-2 border-y-2 border-teatips rounded-r bg-teatips flex items-center cursor-pointer"
          onClick={updateNotes}
        >
          <FiCheck className="text-lg" />
        </div>
      </div>

      <FiList className="text-xl mx-2" onClick={() => setOpen(!open)} />
      <span className="cursor-pointer" onClick={() => setOpen(!open)}>
        Note e indicazioni speciali
      </span>
    </div>
  )
}

export default CartNotes
