import { gql, useLazyQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { CartContext } from '../../providers/CartContext'
import Loader from '../Loader'
import LineItem from './LineItem'

const CartLines = () => {
  const { cart } = useContext(CartContext)

  const [_, { loading, data, refetch }] = useLazyQuery(
    gql`
      query cartLines($cartId: ID!) {
        cart(id: $cartId) {
          id
          lines(first: 250) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `,
    { variables: { cartId: cart.id }, ssr: false, fetchPolicy: 'network-only' }
  )

  useEffect(() => {
    if (!cart.id) return
    refetch({ cartId: cart.id })
  }, [refetch, cart.id, cart.lastUpdate])

  if (loading || (!data && cart.id)) {
    return <Loader />
  }

  if (!cart.id || !data.cart || data.cart.lines.edges.length === 0) {
    return (
      <div className="bg-background rounded p-4 mb-2 flex flex-col justify-center items-center text-center">
        <div className="mb-2 max-w-sm">
          Al momento il tuo carrello è vuoto, ma si riempirà presto di buon tè.
        </div>
      </div>
    )
  }

  return (
    <div className="overflow-y-auto">
      {data.cart.lines.edges
        .map((edge: any) => edge.node)
        .map((line: any) => (
          <LineItem key={line.id} onChange={() => {}} {...line} />
        ))}
    </div>
  )
}

export default CartLines
