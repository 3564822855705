export const viewItemList =
  ({ dispatch }: { dispatch: any}) =>
  (itemListId: string, items: { item_id: string; }[]) => {
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'view_item_list',
        content: {
          item_list_id: itemListId,
          items
        }
      }
    })
  }

export const selectItem =
  ({ dispatch }: { dispatch: any}) =>
  (itemListId: string, item: { item_id: string; price: number; quantity: number, discount: number }) => {
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'select_item',
        content: {
          item_list_id: itemListId,
          items: [item]
        }
      }
    })
  }

export const viewItem =
  ({ dispatch }: { dispatch: any}) =>
  (currency: string, value: number, item: { item_id: string; price: number; quantity: number, discount?: number, item_variant: string }) => {
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'view_item',
        content: {
          currency,
          value,
          items: [item]
        }
      }
    })
  }

  export const addToCart =
  ({ dispatch }: { dispatch: any}) =>
  (currency: string, value: number, item: { item_id: string; price: number; quantity: number; discount?: number; item_variant: string }) => {
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'add_to_cart',
        content: {
          currency,
          value,
          items: [item]
        }
      }
    })
  }

  export const viewCart =
  ({ dispatch }: { dispatch: any}) =>
  (items: { item_id: string; price: number; quantity: number, discount: number }[]) => {
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'add_to_cart',
        content: {
          items
        }
      }
    })
  }

  export const removeFromCart =
  ({ dispatch }: { dispatch: any}) =>
  (currency: string, value: number, item: { item_id: string; price: number; quantity: number, item_variant: string }) => {
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'remove_from_cart',
        content: {
          currency,
          value,
          items: [item]
        }
      }
    })
  }

export const beginCheckout =
  ({ dispatch }: { dispatch: any}) =>
  (currency: string, value: number, items: { item_id: string; price: number; quantity: number, discount: number }[]) => {
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'begin_checkout',
        content: {
          currency,
          value,
          items,
        }
      }
    })
  }